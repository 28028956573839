<template>
  <div class="details-header--details">
    <h2>Filtry ip:</h2>
    <ul class="list-unstyled">
      <template v-for="(ip, ipIndex) in formattedIps">
        <li :key="`ipString-${ipIndex}`">
          {{ip}}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'Ips',
    props: {
      ips: {type: Array, default: () => []}
    },
    computed: {
      formattedIps () {
        return this.ips.map(ip => ip.join('.'))
      }
    }
  }
</script>

<style scoped>

</style>
