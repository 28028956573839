<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid box-component">
            <div class="box-body box-details-header">
              <div class="details-header">
                <div class="details-header--avatar">
                  <div id="imgframe">
                    <img
                      id="avatarImage"
                      class="img-responsive img-thumbnail"
                    >
                  </div>
                </div>
                <div class="details-header--details">
                  <h2>Dane kontrahenta</h2>
                  <ul class="list-unstyled">
                    <li><label>Symbol:</label> {{ contractor.symbol }}</li>
                    <li><label>Nazwa:</label> {{ contractor.label }}</li>
                    <li><label>Numer telefonu:</label> {{ contractor.phone }}</li>
                    <li><label>Adres e-mail:</label> {{ contractor.email }}</li>
                  </ul>
                </div>
                <div class="details-header--details details-header--without-header">
                  <ul class="list-unstyled">
                    <li><label>Opis:</label> {{ contractor.description }}</li>
                  </ul>
                </div>
                <div class="details-header--details">
                  <h2>Jednostki</h2>
                  <ul class="list-unstyled">
                    <li
                      v-for="(unit, index) in contractor.units"
                      :key="index"
                    >
                      {{ unit.label }}
                    </li>
                  </ul>
                </div>
                <Ips :ips="ips" v-if="$auth.isAllowed({service: 'core', action: 'get_contractor_access_ips'})"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modals -->
    <contractor-edit-modal
      :show="contractorEditVisible"
      :form-data="contractor"
      @close="contractorEditVisible = false"
      @success="contractorEdited => contractor = contractorEdited"
    />
    <LanguageEdit
      :show="languageEditVisible"
      :form-data="contractor"
      @success="() => languageChangedSuccesful()"
      @close="() => languageEditVisible = false"
    ></LanguageEdit>
    <IpFilters
      v-if="ipFiltersModalVisible"
      :show="ipFiltersModalVisible"
      :form-data="ips"
      :service="'core'"
      @close="ipFiltersModalVisible = false"
      @success="ipFiltersSetSuccesfully"
    >
    </IpFilters>
  </div>
</template>

<script>
import api from '../../../../../api'
import DynamicBreadcrumb from '../../../share/mixins/DynamicBreadcrumb'
import ContractorEditModal from './modal/ContractorEdit'
import Page from '../../../../share/content/Page'
import LanguageEdit from './modal/LanguageEdit'
import IpFilters from './modal/IpFilters'
import Ips from '../../../share/admin/ips/Ips'

export default {
  components: {
    LanguageEdit,
    Ips,
    IpFilters,
    ContractorEditModal
  },
  mixins: [
    DynamicBreadcrumb,
    Page
  ],
  data () {
    return {
      events: {
        editContractor: 'core:contractor:editContractor',
        editLanguage: 'core:contractor:editLanguage',
        editContractorIps: 'core:contractor:editContractorIps'
      },
      headerTitle: { title: 'Kontrahenci', description: 'Szczegóły' },
      contractorEditVisible: false,
      actionEditName: 'contractor_edit',
      urlActions: {
        getContent: { method: 'get', url: `/contractors/${this.$route.params.id}` }
      },
      contractor: {
        symbol: '',
        label: '',
        description: '',
        phone: '',
        email: '',
        units: [],
      },
      ips: [],
      ipFiltersModalVisible: false,
      languageEditVisible: false,
    }
  },
  mounted () {
    this.$events.on(this.events.editContractor, this.editContractor)
    this.$events.on(this.events.editContractorIps, this.editContractorIps)
    this.$events.on(this.events.editLanguage, this.editLanguage)

    this.loadIps()
    this.$nextTick(() => {
      this.loadContent()
    })
  },
  methods: {
    languageChangedSuccesful () {
      this.languageEditVisible = false
      this.loadContent()
    },
    editContractor () {
      this.contractorEditVisible = true
    },
    editLanguage () {
      this.languageEditVisible = true
    },
    editContractorIps () {
      this.ipFiltersModalVisible = true
    },
    ipFiltersSetSuccesfully () {
      this.ipFiltersModalVisible = false
      this.loadIps()
    },
    loadContent () {
      this.toggleLoading()

      api.request('core', this.urlActions.getContent.method, this.urlActions.getContent.url)
        .then((response) => {
          this.toggleLoading()
          this.contractor = response.data.contractor

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(this.$route.name, this.contractor.label)

          this.showImage(response.data.avatarImage)
          this.dataLoaded = true
        })
        .catch(() => {
          this.toggleLoading()
        })
    },
    loadIps () {
      if(this.$auth.isAllowed({service: 'core', action: 'get_contractor_access_ips'})) {
        this.toggleLoading()

        api.request('core', 'get', `/contractors/${this.$route.params.id}/access-ips`)
          .then((response) => {
            this.toggleLoading()
            this.ips = response.data.map(ip => ip.address.split('.'))
          })
          .catch(() => {
            this.toggleLoading()
          })
      }
    },
    actionRun (urlAction, params = null) {
      api.request('core', urlAction.method, urlAction.url, params)
        .then(() => {
          this.toggleLoading()
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: 'Wystąpił błąd'
          })
        })
    },
    showImage (file) {
      let img = document.getElementById('avatarImage')
      img.src = `data:image-png;base64,${file}`
    }
  }
}
</script>

<style scoped>
.label {
    display: inline-block;
    margin-bottom: 0.3em;
    vertical-align: middle;
}
</style>
