<template>
  <form>
    <select-type
      v-model="value['locale']"
      :label="'Język'"
      :name="'languageSelect'"
      :options="languages"
    />
  </form>
</template>

<script>
  import FormModalValidation from '../../../../../share/form/FormModalValidation'
  import SelectType from '../../../../../share/form/type/SelectType'

  export default {
    components: {
      SelectType
    },
    mixins: [
      FormModalValidation
    ],
    props: {
      value: {}
    },
    data () {
      return {
        languages: [ {value: 'pl', label: 'Polski'}, {value: 'en', label: 'Angielski'}, {value: 'sk', label: 'Słowacki'}, {value: 'cs', label: 'Czeski'} ]
      }
    }
  }
</script>
