<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/ContractorEdit'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  data () {
    return {
      title: 'Edycja danych kontrahenta',
      okText: 'Zmień'
    }
  },
  methods: {
    submitForm () {
      api.request('core', 'put', `/contractors/${this.$route.params.id}`, this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$emit('success', this.model)
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Zaktualizowano dane kontrahenta'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      return {
        symbol: this.model.symbol,
        label: this.model.label,
        description: this.model.description,
        phone: this.model.phone,
        email: this.model.email,
        avatar: this.model.avatar,
        units: this.model.unitIds,
        userGuide: this.model.userGuide
      }
    }
  }
}
</script>
